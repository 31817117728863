enum QueryKeys {
   Budget = 'budget',
   Categories = 'categories',
   CategoryGroups = 'categoryGroups',
   UserSettings = 'userSettings',
   Plans = 'plans',
   Subscription = 'subscription',
   TransactionSplits = 'transactionSplits',
   Transactions = 'transactions',
   TransactionDrafts = 'transactionDrafts',
   TransactionTemplateSplits = 'transactionTemplateSplits',
   TransactionTemplates = 'transactionTemplates',
   UserProfile = 'userProfile',
   Collaborations = 'collaborations',
   Users = 'users',
   Offer = 'offer',
   Target = 'target',
   Onboarding = 'onboarding',
   OnboardingEvents = 'onboardingEvents',
   BankAccount = 'bankAccount',
   NPSSurvey = 'npsSurvey',
   ReferralCode = 'referral-code',
   AssignmentRecords = 'assignment-records',
   Filters = 'filters',
   Loans = 'loans',
   LoansActivities = 'loans-activities',
   LoanSchedule = 'loan-schedule'
}

export default QueryKeys
