import { setupWorker, rest } from 'msw'
import './data'
import {
   CategoryGroupDto,
   CategoryDto,
   PlanDto,
   UserDto,
   UserSettingsDto,
   TransactionSplitDto
} from '../src/api/contracts/models'
import dayjs from 'dayjs'

// 2. Define request handlers and response resolvers.
const worker = setupWorker(
   rest.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/budget/`,
      (req, res, ctx) => {
         return res(
            ctx.delay(100),
            ctx.status(202, 'Mocked status'),
            ctx.json(JSON.parse(localStorage.budget))
         )
      }
   ),

   rest.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/budget/:budgetId/transactions`,
      (req, res, ctx) => {
         return res(
            ctx.delay(100),
            ctx.status(202, 'Mocked status'),
            ctx.json(JSON.parse(localStorage.transactions))
         )
      }
   ),

   rest.delete(
      `${process.env.VUE_APP_API_BASE_URL}/api/transactions/:id`,
      (req, res, ctx) => {
         const { id } = req.params
         const transactions = JSON.parse(localStorage.transactions)
         const transaction = transactions.find((m) => m.id == id)
         const transactionIndex = transactions.indexOf(transaction)
         if (transactionIndex > -1) {
            transactions.splice(transactionIndex, 1)
            localStorage.transactions = JSON.stringify(transactions)
         }

         return res(
            ctx.delay(100),
            ctx.status(202, 'Mocked status'),
            ctx.json(JSON.parse(localStorage.transactions))
         )
      }
   ),

   rest.put(
      `${process.env.VUE_APP_API_BASE_URL}/api/transactions/:id`,
      (req, res, ctx) => {
         const { id } = req.params
         const transactions = JSON.parse(localStorage.transactions)
         const transaction = transactions.find((m) => m.id == id)
         const transactionIndex = transactions.indexOf(transaction)
         if (transactionIndex > -1) {
            transactions[transactionIndex] = req.body
            localStorage.transactions = JSON.stringify(transactions)
         }

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/transactions`,
      (req, res, ctx) => {
         const transactions = JSON.parse(localStorage.transactions)

         const newTransaction = req.body as TransactionSplitDto

         // NOTE: czemu pomimo typowania newTransaction.date jest stringiem?
         const transactionDate = new Date(newTransaction.date)

         newTransaction.periodKey =
            transactionDate.getFullYear().toString() +
            ('0' + (transactionDate.getMonth() + 1)).slice(-2).toString()

         const profile = JSON.parse(localStorage.userProfile) as UserDto

         transactions.push({
            ...newTransaction,
            paidBy: profile.name,
            paidById: profile.id
         })
         localStorage.transactions = JSON.stringify(transactions)

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/budget/:budgetId/categories`,
      (req, res, ctx) => {
         return res(
            ctx.delay(100),
            ctx.status(202, 'Mocked status'),
            ctx.json(JSON.parse(localStorage.categories))
         )
      }
   ),

   rest.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/categories`,
      (req, res, ctx) => {
         const categories = JSON.parse(localStorage.categories)
         const newCategory = req.body as CategoryDto
         categories.push(newCategory)
         localStorage.categories = JSON.stringify(categories)
         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.delete(
      `${process.env.VUE_APP_API_BASE_URL}/api/categories/:id`,
      (req, res, ctx) => {
         const { id } = req.params
         const categories = JSON.parse(localStorage.categories)
         const category = categories.find((m) => m.id == id)

         const categoryIndex = categories.indexOf(category)
         if (categoryIndex > -1) {
            categories.splice(categoryIndex, 1)
            localStorage.categories = JSON.stringify(categories)
         }

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.put(
      `${process.env.VUE_APP_API_BASE_URL}/api/categories/:id`,
      (req, res, ctx) => {
         const { id } = req.params

         const categories = JSON.parse(localStorage.categories)

         const category = categories.find((m) => m.id == id)

         const updatedCategory = req.body as CategoryDto

         const categoryIndex = categories.indexOf(category)
         categories[categoryIndex] = updatedCategory

         localStorage.categories = JSON.stringify(categories)

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.put(
      `${process.env.VUE_APP_API_BASE_URL}categories/:id/order/:order`,
      (req, res, ctx) => {
         const { id, order } = req.params

         const categories = JSON.parse(localStorage.categories)

         const category = categories.find((m) => m.id == id)
         const categoryOrder = parseInt(order)

         // NOTE: order changed, need to update other Categories
         if (category.order != categoryOrder) {
            for (const el of categories) {
               if (
                  el.id == id ||
                  el.categoryGroupId != category.categoryGroupId
               ) {
                  continue
               }

               //el.order = computeOrder(category.order, categoryOrder, el.order)

               const elementIndex = categories.indexOf(el)
               categories[elementIndex] = el
            }
         }

         const categoryIndex = categories.indexOf(category)
         categories[categoryIndex] = {
            ...categories[categoryIndex],
            order: categoryOrder
         }

         localStorage.categories = JSON.stringify(categories)

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/budget/:budgetId/category-groups`,
      (req, res, ctx) => {
         return res(
            ctx.delay(100),
            ctx.status(202, 'Mocked status'),
            ctx.json(JSON.parse(localStorage.categoryGroups))
         )
      }
   ),

   rest.put(
      `${process.env.VUE_APP_API_BASE_URL}/api/category-groups/:id`,
      (req, res, ctx) => {
         const { id } = req.params
         const categoryGroups = JSON.parse(localStorage.categoryGroups)
         const categoryGroup = categoryGroups.find((m) => m.id == id)

         const updatedCategoryGroup = req.body as CategoryGroupDto

         const categoryGroupIndex = categoryGroups.indexOf(categoryGroup)
         categoryGroups[categoryGroupIndex] = updatedCategoryGroup

         localStorage.categoryGroups = JSON.stringify(categoryGroups)

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/category-groups`,
      (req, res, ctx) => {
         const categoryGroups = JSON.parse(localStorage.categoryGroups)
         const newCategoryGroup = req.body as CategoryGroupDto
         categoryGroups.push(newCategoryGroup)
         localStorage.categoryGroups = JSON.stringify(categoryGroups)
         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.delete(
      `${process.env.VUE_APP_API_BASE_URL}/api/category-groups/:id`,
      (req, res, ctx) => {
         const { id } = req.params
         const categoryGroups = JSON.parse(localStorage.categoryGroups)
         const categoryGroup = categoryGroups.find((m) => m.id == id)

         const categoryIndex = categoryGroups.indexOf(categoryGroup)
         if (categoryIndex > -1) {
            categoryGroups.splice(categoryIndex, 1)
            localStorage.categoryGroups = JSON.stringify(categoryGroups)
         }

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/budget/:budgetId/plans`,
      (req, res, ctx) => {
         return res(
            ctx.delay(100),
            ctx.status(202, 'Mocked status'),
            ctx.json(JSON.parse(localStorage.plans))
         )
      }
   ),

   rest.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/plans`,
      (req, res, ctx) => {
         const newPlan = req.body as PlanDto

         const plans = JSON.parse(localStorage.plans)

         const plan = plans.find(
            (m) =>
               m.categoryId == newPlan.categoryId &&
               m.periodKey == newPlan.periodKey
         )
         const planIndex = plans.indexOf(plan)

         if (planIndex > -1) {
            plans[planIndex] = req.body
         } else {
            plans.push(newPlan)
         }

         localStorage.plans = JSON.stringify(plans)

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.put(
      `${process.env.VUE_APP_API_BASE_URL}/api/plans/:id/note`,
      (req, res, ctx) => {
         const { id } = req.params
         const plans = JSON.parse(localStorage.plans)
         const plan = plans.find((m) => m.id == id)
         const planIndex = plans.indexOf(plan)
         if (planIndex > -1) {
            plans[planIndex] = { ...plans[planIndex], note: req.body }
            localStorage.plans = JSON.stringify(plans)
         }

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/subscription`,
      (req, res, ctx) => {
         return res(
            ctx.delay(100),
            ctx.status(202, 'Mocked status'),
            ctx.json(JSON.parse(localStorage.subscription))
         )
      }
   ),

   rest.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/user-profile`,
      (req, res, ctx) => {
         return res(
            ctx.delay(100),
            ctx.status(202, 'Mocked status'),
            ctx.json(JSON.parse(localStorage.userProfile))
         )
      }
   ),

   rest.put(
      `${process.env.VUE_APP_API_BASE_URL}/api/user-profile`,
      (req, res, ctx) => {
         const userProfile = req.body as UserDto
         localStorage.userProfile = JSON.stringify(userProfile)
         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/onboarding/reason`,
      (req, res, ctx) => {
         const profile = JSON.parse(localStorage.userProfile)

         profile.joinReasons = req.body

         localStorage.userProfile = JSON.stringify(profile)

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   ),

   rest.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/onboarding/finish`,
      (req, res, ctx) => {
         const profile = JSON.parse(localStorage.userProfile)

         profile.finishedOnboarding = true

         localStorage.userProfile = JSON.stringify(profile)

         return res(ctx.delay(100), ctx.status(202, 'Mocked status'))
      }
   )
)

// 3. Start the Service Worker.
export default worker
