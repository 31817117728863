import {
   UserManager,
   UserManagerSettings,
   WebStorageStateStore
} from 'oidc-client'
import { CookieStorage } from 'cookie-storage'
import dayjs from 'dayjs'

const cookieStorage = new CookieStorage({
   expires: dayjs().add(14, 'day').toDate(),
   domain: process.env.VUE_APP_APP_DOMAIN,
   path: '/'
})

export default class AuthService {
   STS_DOMAIN: string
   settings: UserManagerSettings
   userManager: UserManager

   constructor() {
      this.STS_DOMAIN = process.env.VUE_APP_IS4_API_BASE_URL!
      this.settings = {
         userStore: new WebStorageStateStore({ store: cookieStorage }),
         stateStore: new WebStorageStateStore({ store: cookieStorage }),
         authority: this.STS_DOMAIN,
         client_id: 'easybudget.client',
         redirect_uri: `${process.env.VUE_APP_APP_BASE_URL}/callback`,
         automaticSilentRenew: true,
         silent_redirect_uri: `${process.env.VUE_APP_APP_BASE_URL}/silentRenew`,
         response_type: 'code',
         scope: 'openid profile easybudget.api',
         post_logout_redirect_uri: `${process.env.VUE_APP_APP_BASE_URL}`,
         filterProtocolClaims: true
      }
      this.userManager = new UserManager(this.settings)
   }

   async isLoggedIn() {
      return this.userManager.getUser().then((user) => {
         return user != null && !user.expired
      })
   }

   async silentLogin() {
      await this.userManager.signinSilent()
   }

   getUser() {
      return this.userManager.getUser()
   }

   getUserId() {
      return this.userManager.getUser().then((data) => {
         return data?.profile?.sub
      })
   }

   login(redirectUrl: string | null = null) {
      return this.userManager.signinRedirect({
         state: redirectUrl ?? window.location.href
      })
   }

   logout(redirectUrl: string | null = null) {
      this.userManager.signoutRedirect({
         state: redirectUrl ?? window.location.href
      })
   }

   getAccessToken() {
      return this.userManager.getUser().then((data) => {
         return data?.id_token
      })
   }
}

const authService = new AuthService()
export const useAuthService = () => authService
