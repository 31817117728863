import { UserDto } from '@/api/contracts/models'
import { useUserProfileQueryPrefetch } from '@/composables/queries/useUserProfileQuery'
import QueryKeys from '@/vue-query/QueryKeys'
import { QueryClient } from 'vue-query'
const myClient = new QueryClient()

const accountDisabledGuard = async (to, from, next) => {
   const queryClient = myClient
   let data = queryClient.getQueriesData<UserDto>(QueryKeys.UserProfile)

   if (data.length === 0) {
      await useUserProfileQueryPrefetch(queryClient)
      data = queryClient.getQueriesData<UserDto>(QueryKeys.UserProfile)
   }

   if (!data[0][1]?.isDisabled) {
      next()
   } else {
      next({ name: 'logout' })
   }
}

export default accountDisabledGuard
