import LoadingComponent from '@/components/LoadingComponent.vue'
import ErrorComponent from '@/components/ErrorComponent.vue'
import { defineAsyncComponent, defineComponent, h } from 'vue'

export const lazyLoadView = (loader, showLoadingComponent = true) => {
   return () =>
      Promise.resolve(
         defineComponent({
            render() {
               return h(
                  defineAsyncComponent({
                     loader: loader,
                     loadingComponent: showLoadingComponent
                        ? LoadingComponent
                        : undefined,
                     delay: 100,
                     errorComponent: ErrorComponent,
                     timeout: 30000
                  })
               )
            }
         })
      )
}
