import authRoutes from './auth'
import authGuard from '@/auth/AuthGuard'
import { RouteRecordRaw } from 'vue-router'
import { lazyLoadView } from './helpers'
import subscriptionGuard from './subscriptionGuard'
import accountDisabledGuard from './accountDisabledGuard'
export const desktopRoutes: Array<RouteRecordRaw> = [
   ...authRoutes,
   {
      path: '/health',
      name: 'health',
      component: lazyLoadView(
         () => import(/* webpackPrefetch: true */ '../views/_Health.vue')
      )
   },
   {
      path: '/',
      name: 'app',
      beforeEnter: [authGuard, accountDisabledGuard],
      component: () =>
         import(/* webpackPrefetch: true */ '../views/MainView.vue'),
      children: [
         {
            path: 'subscription-expired',
            name: 'subscriptionExpired',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../views/SubscriptionExpired.vue'
                  )
            )
         },
         {
            path: 'subscription-extended',
            name: 'subscriptionExtended',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../views/SubscriptionExtended.vue'
                  )
            )
         },
         {
            path: 'test-data/:secret/:transactionsPerMonth',
            name: 'testData',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../views/test-data/TestDataGenerate.vue'
                  )
            )
         },
         {
            path: 'demo-data/:secret',
            name: 'demoData',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../views/test-data/DemoDataGenerate.vue'
                  )
            )
         },
         {
            path: 'budget',
            beforeEnter: subscriptionGuard,
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../layouts/LayoutDefault.vue'
                  )
            ),
            children: [
               {
                  path: ':budgetId/import-budget',
                  name: 'budgetImport',
                  meta: { showSidePanel: false },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/ImportBudgetView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/statistics',
                  name: 'statistics',
                  meta: { showSidePanel: false },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/StatisticsViewDesktop.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/import',
                  name: 'importTransactions',
                  meta: { showSidePanel: false },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/ImportTransactionsView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/settings',
                  name: 'budgetSettings',
                  meta: { showSidePanel: false },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/BudgetSettingsViewDesktop.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey',
                  alias: [':budgetId'],
                  name: 'budget',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/BudgetViewContainerDesktop.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/loans/:loanId?',
                  name: 'loans',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../features/loans/views/LoanView.vue'
                           )
                     )
                  }
               }
            ]
         },
         {
            path: 'budgets',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../layouts/LayoutEmpty.vue'
                  )
            ),
            children: [
               {
                  path: '',
                  name: 'budgetsList',
                  component: lazyLoadView(
                     () =>
                        import(
                           /* webpackPrefetch: true */ '../views/SettingsBudgetListViewDesktop.vue'
                        )
                  )
               }
            ]
         },
         {
            path: 'settings',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../layouts/LayoutEmpty.vue'
                  )
            ),
            children: [
               {
                  path: 'subscription',
                  name: 'settingsSubscription',
                  meta: { header: 'Subskrypcja', showSidePanel: false },
                  component: lazyLoadView(
                     () =>
                        import(
                           /* webpackPrefetch: true */ '../views/SettingsSubscriptionViewDesktop.vue'
                        )
                  )
               },
               {
                  path: 'reminders',
                  name: 'settingsReminder',
                  meta: { header: 'Przypomnienia', showSidePanel: false },
                  component: lazyLoadView(
                     () =>
                        import(
                           /* webpackPrefetch: true */ '../views/SettingsReminderViewDesktop.vue'
                        )
                  )
               },
               {
                  path: 'referral',
                  name: 'settingsReferral',
                  meta: { header: 'Polecaj', showSidePanel: false },
                  component: lazyLoadView(
                     () =>
                        import(
                           /* webpackPrefetch: true */ '../views/SettingsReferralViewDesktop.vue'
                        )
                  )
               }
            ]
         }
      ]
   }
]
