import { SubscriptionDto } from '@/api/contracts/models'
import { useSubscriptionQueryPrefetch } from '@/composables/queries/useSubscriptionQuery'
import QueryKeys from '@/vue-query/QueryKeys'
import { QueryClient } from 'vue-query'
const myClient = new QueryClient()

const subscriptionGuard = async (to, from, next) => {
   const queryClient = myClient
   let data = queryClient.getQueriesData<SubscriptionDto>(
      QueryKeys.Subscription
   )

   if (data.length === 0) {
      await useSubscriptionQueryPrefetch(queryClient)
      data = queryClient.getQueriesData<SubscriptionDto>(QueryKeys.Subscription)
   }

   if (!data[0][1]?.isExpired) {
      next()
   } else {
      next({ name: 'subscriptionExpired' })
   }
}

export default subscriptionGuard
