import { createI18n } from 'vue-i18n'
import { useI18n } from 'vue-i18n'
import pl from './locales/pl.json'
import en from './locales/en.json'
import { Language } from '@/api/contracts/models'

type MessageSchema = typeof pl
type SupportedLanguages = `${Language}`

const i18n = createI18n<[MessageSchema], SupportedLanguages>({
   legacy: false,
   locale: 'PL',
   fallbackLocale: 'EN',
   messages: {
      PL: pl,
      EN: en,
      DE: en
   }
})

export function useInternationalization() {
   return i18n
}

export function useTranslations() {
   const { t } = useI18n<[MessageSchema]>()
   return { t }
}
