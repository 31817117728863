import dayjs, { Dayjs } from 'dayjs'

if (!localStorage.isDataSet) {
   var today = new Date()
   var periodKey = dayjs(today).format('YYYYMM')
   localStorage.transactions = JSON.stringify([
      {
         id: '1',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '1',
         value: 1000,
         type: 'Inflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '2',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '2',
         value: 1000,
         type: 'Inflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '3',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '2',
         value: 1000,
         type: 'Inflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '4',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '3',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '5',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '4',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '6',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '4',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '7',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '4',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '8',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '4',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '9',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '4',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '10',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '4',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '11',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '4',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      },
      {
         id: '12',
         budgetId: '1',
         periodKey: periodKey,
         date: today,
         categoryId: '4',
         value: 1000,
         type: 'Outflow',
         note: 'Notatka moja fajna',
         paidBy: 'Marcin'
      }
   ])

   localStorage.categoryGroups = JSON.stringify([
      {
         id: '1',
         budgetId: '1',
         name: 'Przychód',
         order: 0,
         type: 'Income'
      },
      {
         id: '2',
         budgetId: '1',
         name: 'Wydatki - dom',
         order: 1,
         type: 'Expense',
         expenseType: 'Housing'
      },
      {
         id: '4',
         budgetId: '1',
         name: 'Wydatki - jedzenie',
         order: 2,
         type: 'Expense',
         expenseType: 'Food'
      },
      {
         id: '12',
         budgetId: '1',
         name: 'Wydatki - transport',
         order: 2,
         type: 'Expense',
         expenseType: 'Transport'
      },
      {
         id: '13',
         budgetId: '1',
         name: 'Wydatki - osobiste',
         order: 2,
         type: 'Expense',
         expenseType: 'Self'
      },
      {
         id: '3',
         budgetId: '1',
         name: 'Oszczędności',
         order: 3,
         type: 'Saving'
      },
      {
         id: '5',
         budgetId: '1',
         name: 'Oszczędności 5',
         order: 4,
         type: 'Saving'
      },
      {
         id: '6',
         budgetId: '1',
         name: 'Oszczędności 6',
         order: 5,
         type: 'Saving'
      },
      {
         id: '7',
         budgetId: '1',
         name: 'Oszczędności 7',
         order: 6,
         type: 'Saving'
      },
      {
         id: '8',
         budgetId: '1',
         name: 'Oszczędności 8',
         order: 7,
         type: 'Saving'
      },
      {
         id: '9',
         budgetId: '1',
         name: 'Oszczędności 9',
         order: 8,
         type: 'Saving'
      },
      {
         id: '10',
         budgetId: '1',
         name: 'Oszczędności 10',
         order: 9,
         type: 'Saving'
      },
      {
         id: '11',
         budgetId: '1',
         name: 'Oszczędności 11',
         order: 10,
         type: 'Saving'
      }
   ])

   localStorage.categories = JSON.stringify([
      {
         id: '1',
         name: 'Praca 1',
         order: 1,
         categoryGroupId: '1',
         type: 'Income'
      },
      {
         id: '2',
         name: 'Praca 2',
         order: 0,
         categoryGroupId: '1',
         type: 'Income'
      },
      {
         id: '3',
         name: 'Jedzenie dom',
         order: 0,
         categoryGroupId: '4',
         type: 'Expense'
      },
      {
         id: '4',
         name: 'Rachunki',
         order: 0,
         categoryGroupId: '2',
         type: 'Expense'
      },
      {
         id: '5',
         name: 'Halo',
         order: 0,
         categoryGroupId: '3',
         type: 'Expense'
      }
   ])

   localStorage.plans = JSON.stringify([
      {
         id: '1',
         budgetId: '1',
         periodKey: periodKey,
         categoryId: '1',
         value: 2000,
         note: 'Notatka ma'
      },
      {
         id: '2',
         budgetId: '1',
         periodKey: periodKey,
         categoryId: '2',
         value: 1000,
         note: 'Notatka ma'
      },
      {
         id: '3',
         budgetId: '1',
         periodKey: periodKey,
         categoryId: '3',
         value: 1000,
         note: 'Notatka ma'
      },
      {
         id: '4',
         budgetId: '1',
         periodKey: periodKey,
         categoryId: '4',
         value: 1000,
         note: 'Notatka ma'
      }
   ])

   localStorage.subscription = JSON.stringify({
      expiresAt: new Date(2022, 1, 1),
      type: 1,
      isExpired: false
   })

   localStorage.budget = JSON.stringify({
      id: 1
   })

   localStorage.userProfile = JSON.stringify({
      id: 1,
      name: 'EasyBudget User',
      email: 'email@domena.pl',
      finishedOnboarding: false
   })

   localStorage.notificationsSettings = JSON.stringify({
      budgetingNotificationEnabled: false
   })

   localStorage.isDataSet = true
}
