import { Emitter } from 'mitt'
import { App, ComputedRef, createApp, Plugin } from 'vue'
import EasyToast from './components/EasyToast.vue'
import { EasyToastEvents, EasyToastOptions } from './types'

let eventBus
let isMobile

export const EasyToastPlugin: Plugin = {
   install(app: App, options: EasyToastOptions) {
      if (!options.eventBus) {
         console.error(
            'Emitter (mitt) not provided with EasyToastPlugin app.use().'
         )
      }

      isMobile = options.isMobile
      eventBus = options.eventBus
      const div = document.createElement('div')
      document.body.appendChild(div)
      const easyToastInstance = createApp(EasyToast)
      easyToastInstance.mount(div)
   }
}

export function useEventBus(): Emitter<EasyToastEvents> {
   return eventBus
}

export function useIsMobile(): ComputedRef<boolean> {
   return isMobile
}
