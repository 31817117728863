import { useDeviceInfo } from '@/composables/useDeviceInfo'
import { createRouter, createWebHistory } from 'vue-router'
import { desktopRoutes } from './desktopRoutes'
import { mobileRoutes } from './mobileRoutes'

const { isMobile } = useDeviceInfo()

export const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes: isMobile.value ? mobileRoutes : desktopRoutes
})
