import { useSubscriptionApi } from '@/api/Subscription'
import { QueryClient, useQuery } from 'vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { SubscriptionDto } from '@/api/contracts/models'

export function useSubscriptionQuery() {
   const client = useSubscriptionApi()
   return useQuery([QueryKeys.Subscription], () => client.getUserSubscription())
}

export function useSubscriptionQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useSubscriptionApi()
   return queryClient.prefetchQuery<SubscriptionDto>(
      [QueryKeys.Subscription],
      () => client.getUserSubscription()
   )
}
