import { defineStore } from 'pinia'
import { useParams } from '../useParams'
import { useBudgetQuery } from '../queries/useBudgetQuery'
import { Currency } from '@/api/contracts/models'

export const useBudgetStore = defineStore('budget', () => {
   const { budgetId } = useParams()

   const budget = useBudgetQuery(budgetId)

   const currencyLocale = () => {
      if (!budget.value?.currency) {
         return 'pl-PL'
      }

      switch (budget.value?.currency) {
         case Currency.PLN:
            return 'pl-PL'
         default:
            return 'en-EN'
      }
   }

   return {
      budget,
      currencyLocale
   }
})
