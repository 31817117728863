import { useAuthService } from './AuthService'

const authService = useAuthService()
const authGuard = async (to, from, next) => {
   if (
      (await authService.isLoggedIn()) ||
      process.env.VUE_APP_API_MODE === 'Mock'
   ) {
      next()
   } else {
      await authService.login(window.location.origin + to.fullPath)
   }
}

export default authGuard
