import { RouteRecordRaw } from 'vue-router'

const authRoutes: Array<RouteRecordRaw> = [
   {
      path: '/silentRenew',
      name: 'silentRenew',
      component: () => import('../views/auth/SilentRenew.vue')
   },
   {
      path: '/callback',
      name: 'callback',
      component: () => import('../views/auth/Callback.vue')
   },
   // {
   //     path: "/accept-invitation",
   //     name: "AcceptInvitation",
   //     component: () => import("../views/AcceptInvitation.vue")
   // },
   {
      path: '/logout',
      name: 'logout',
      component: () => import('../views/auth/Logout.vue')
   }
]

export default authRoutes
