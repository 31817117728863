import { useEventBus } from '@/composables/useEventBus'
import { publishEvent } from '@/helpers/Analytics'

export function setUpGA4Events() {
   const eventBus = useEventBus()

   eventBus.on('onboardingSurveyCompleted', () => {
      publishEvent('onboarding_survey_completed')
   })

   eventBus.on('onboardingFirstStepCompleted', () => {
      publishEvent('onboarding_1_step_completed')
   })

   eventBus.on('onboardingSecondStepCompleted', () => {
      publishEvent('onboarding_2_step_completed')
   })

   eventBus.on('onboardingThirdStepCompleted', () => {
      publishEvent('onboarding_3_step_completed')
   })

   eventBus.on('onboardingCompleted', () => {
      publishEvent('onboarding_completed')
   })

   eventBus.on('autoAssignPerformed', () => {
      publishEvent('auto_assign')
   })

   eventBus.on('moneyAssigned', () => {
      publishEvent('money_assigned')
   })

   eventBus.on('transactionAdded', () => {
      publishEvent('transaction_added')
   })

   eventBus.on('templateAdded', () => {
      publishEvent('template_added')
   })

   eventBus.on('templateUsed', () => {
      publishEvent('template_used')
   })

   eventBus.on('targetSet', () => {
      publishEvent('target_set')
   })

   eventBus.on('importCsvStarted', (bankId: string) => {
      publishEvent('import_csv_started', {
         bankId
      })
   })

   eventBus.on('helpOpened', () => {
      publishEvent('help_opened')
   })
}
