import { computed } from 'vue'
import { useWindowSize } from 'vue-window-size'

export function useDeviceInfo() {
   const { width } = useWindowSize()
   const isMobile = computed(() => width.value <= 992)
   const mobileOS = computed(() => getMobileOperatingSystem())

   return {
      isMobile,
      mobileOS
   }
}

function getMobileOperatingSystem() {
   const userAgent = navigator.userAgent

   if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
   }

   if (/android/i.test(userAgent)) {
      return 'Android'
   }

   if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS'
   }

   return 'unknown'
}
