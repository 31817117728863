
import { defineComponent, ref } from 'vue'
import { useEventBus, useIsMobile } from '../EasyToastPlugin'
import AWN from 'awesome-notifications'
import ProgressBar from 'primevue/progressbar'

type Toast = {
   success(message: string)
   warning(message: string)
}

export default defineComponent({
   components: {
      ProgressBar
   },
   setup() {
      const duration = 5000
      const desktopToast = new AWN({
         position: 'bottom-right',
         labels: { success: 'SUKCES' }
      }) as Toast

      let timeout
      let timer
      const eventBus = useEventBus()
      const text = ref('')
      const isVisible = ref(false)
      const isMobile = useIsMobile()
      const progressBarValue = ref(0)

      const startProgressBar = () => {
         const interval = 50
         const step = 100 / (duration / interval)
         timer = setInterval(() => (progressBarValue.value += step), interval)
      }

      const show = (msg) => {
         window.clearTimeout(timeout)

         progressBarValue.value = 0
         text.value = msg
         isVisible.value = true

         startProgressBar()
         timeout = setTimeout(() => hide(), duration)
      }

      const hide = () => {
         text.value = ''
         isVisible.value = false
         window.clearTimeout(timeout)
         window.clearInterval(timer)
      }

      eventBus.on('notifySuccess', (msg) => {
         if (isMobile.value) {
            show(msg)
         } else {
            desktopToast.success(msg)
         }
      })

      eventBus.on('notifyWarning', (msg) => {
         if (isMobile.value) {
            show(msg)
         } else {
            desktopToast.warning(msg)
         }
      })

      return {
         text,
         isVisible,
         hide,
         progressBarValue
      }
   }
})
