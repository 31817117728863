import { useUserProfileQuery } from './queries/useUserProfileQuery'
import { useEventBus } from './useEventBus'

export function useFreshDesk() {
   const { data: profile } = useUserProfileQuery()
   const freshDesk = (window as any).FreshworksWidget
   const hideLauncher = () => {
      freshDesk('hide', 'launcher')
   }

   const openWidget = (articleId?: string) => {
      if (articleId) {
         freshDesk('open', 'article', { id: articleId })
      } else {
         freshDesk('open')
      }

      useEventBus().emit('helpOpened')
   }

   const openContactForm = () => {
      freshDesk('open', 'ticketForm', {
         email: profile?.value?.email,
         name: profile?.value?.name,
         subject: 'Import transakcji'
      })
   }

   return {
      hideLauncher,
      openWidget,
      openContactForm
   }
}
