import { useBudgetApi } from '@/api/Budget'
import { QueryClient, useQuery } from 'vue-query'
import { BudgetDto } from '@/api/contracts/models'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'

export function useBudgetsQuery({ enabled } = { enabled: ref(true) }) {
   const client = useBudgetApi()
   return useQuery([QueryKeys.Budget], () => client.getBudgets(), {
      enabled
   })
}

export function useBudgetsQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useBudgetApi()
   return queryClient.prefetchQuery<BudgetDto[]>([QueryKeys.Budget], () =>
      client.getBudgets()
   )
}
