import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-176fc296"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-easy-toast" }
const _hoisted_2 = { class: "c-easy-toast__in" }
const _hoisted_3 = { class: "c-easy-toast__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProgressBar, {
      class: "c-easy-toast__progress-bar",
      value: _ctx.progressBarValue,
      "show-value": false
    }, null, 8, ["value"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ]),
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
    }, "OK")
  ], 512)), [
    [_vShow, _ctx.isVisible]
  ])
}