import { BudgetDto } from '@/api/contracts/models'
import { Ref, ref, watch } from 'vue'
import { useBudgetsQuery } from './useBudgetsQuery'

export function useBudgetQuery(
   id: Ref<string | null>,
   defaultValue?: BudgetDto
) {
   const { data } = useBudgetsQuery()
   const result = ref<undefined | null | BudgetDto>(undefined)

   const setBudget = () => {
      if (data.value == undefined) {
         return
      }

      const budget = data.value.find((m) => m.id == id.value)

      if (!budget && defaultValue) {
         result.value = defaultValue
      } else if (budget) {
         result.value = { ...budget! }
      } else {
         result.value = null
      }
   }

   watch(
      [data],
      () => {
         setBudget()
      },
      { immediate: true }
   )

   watch([id], () => {
      setBudget()
   })

   return result
}
