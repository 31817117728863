import { ValidationRuleWithoutParams } from '@vuelidate/core'

export const splitsEqual = {
   $validator: splitsEqual$1,
   $message: () => {
      return `The sum of splits must be equal to value of transaction`
   },
   $params: {
      type: 'splitsEqual'
   }
} as ValidationRuleWithoutParams

function splitsEqual$1(value, vm) {
   if (vm.splits.length == 1) {
      return true
   }

   const sumValue = vm.splits.reduce((a, b) => a + b.value, 0)
   if (Math.abs(sumValue - Math.abs(vm.value)) > 0.01) {
      return false
   }

   return true
}
