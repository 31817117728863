import { ContentType, HttpClient, RequestParams } from './base/BaseHttpClient'
import { useHttpClient } from './base/HttpClient'
import {
   AssignmentRecordDto,
   BankAccountDto,
   BudgetCollaborationDto,
   BudgetDto,
   BudgetIdResult,
   BudgetUserDto,
   CategoryDto,
   CategoryGroupDto,
   CreateBudgetRequest,
   ExportBudgetRequest,
   FilterDto,
   LoanActivityDto,
   LoanDto,
   PlanDto,
   ScheduledLoanPaymentDto,
   TargetDto,
   TransactionDraftDto,
   TransactionDto,
   TransactionSplitDto,
   TransactionTemplateDto,
   TransactionTemplateSplitDto,
   UpdateBudgetRequest
} from './contracts/models'

export class BudgetApi<SecurityDataType = unknown> {
   http: HttpClient<SecurityDataType>

   constructor(http: HttpClient<SecurityDataType>) {
      this.http = http
   }

   /**
    * No description
    *
    * @tags Budget
    * @name GetBudgets
    * @request GET:/api/budget
    * @secure
    * @response `200` `(BudgetDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getBudgets = (params: RequestParams = {}) =>
      this.http
         .request<BudgetDto[], void>({
            path: `/api/budget`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name CreateBudget
    * @request POST:/api/budget
    * @secure
    * @response `200` `string` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   createBudget = (data: CreateBudgetRequest, params: RequestParams = {}) =>
      this.http
         .request<string, void>({
            path: `/api/budget`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name Reset
    * @request POST:/api/budget/{budgetId}/reset
    * @secure
    * @response `200` `BudgetIdResult` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   reset = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<BudgetIdResult, void>({
            path: `/api/budget/${budgetId}/reset`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name ImportBudget
    * @request POST:/api/budget/import-budget/{appId}
    * @secure
    * @response `200` `BudgetIdResult` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   importBudget = (
      appId: string,
      data: {
         ContentType?: string
         ContentDisposition?: string
         Headers?: Record<string, string[]>
         /** @format int64 */
         Length?: number
         Name?: string
         FileName?: string
      },
      params: RequestParams = {}
   ) =>
      this.http
         .request<BudgetIdResult, void>({
            path: `/api/budget/import-budget/${appId}`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name UpdateBudget
    * @request PUT:/api/budget/{budgetId}
    * @secure
    * @response `200` `void` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   updateBudget = (
      budgetId: string,
      data: UpdateBudgetRequest,
      params: RequestParams = {}
   ) =>
      this.http
         .request<void, void>({
            path: `/api/budget/${budgetId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name DeleteBudget
    * @request DELETE:/api/budget/{budgetId}
    * @secure
    * @response `200` `void` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   deleteBudget = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<void, void>({
            path: `/api/budget/${budgetId}`,
            method: 'DELETE',
            secure: true,
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name ExportBudget
    * @request POST:/api/budget/{budgetId}/export
    * @secure
    * @response `200` `File` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   exportBudget = (
      budgetId: string,
      data: ExportBudgetRequest,
      params: RequestParams = {}
   ) =>
      this.http
         .request<File, void>({
            path: `/api/budget/${budgetId}/export`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetPlans
    * @request GET:/api/budget/{budgetId}/plans
    * @secure
    * @response `200` `(PlanDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getPlans = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<PlanDto[], void>({
            path: `/api/budget/${budgetId}/plans`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetUsers
    * @request GET:/api/budget/{budgetId}/users
    * @secure
    * @response `200` `(BudgetUserDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getUsers = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<BudgetUserDto[], void>({
            path: `/api/budget/${budgetId}/users`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetTransactions
    * @request GET:/api/budget/{budgetId}/transactions
    * @secure
    * @response `200` `(TransactionDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getTransactions = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<TransactionDto[], void>({
            path: `/api/budget/${budgetId}/transactions`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetTransactionDrafts
    * @request GET:/api/budget/{budgetId}/transaction-drafts
    * @secure
    * @response `200` `(TransactionDraftDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getTransactionDrafts = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<TransactionDraftDto[], void>({
            path: `/api/budget/${budgetId}/transaction-drafts`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetTransactionSplits
    * @request GET:/api/budget/{budgetId}/transaction-splits
    * @secure
    * @response `200` `(TransactionSplitDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getTransactionSplits = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<TransactionSplitDto[], void>({
            path: `/api/budget/${budgetId}/transaction-splits`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetCategoryGroups
    * @request GET:/api/budget/{budgetId}/category-groups
    * @secure
    * @response `200` `(CategoryGroupDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getCategoryGroups = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<CategoryGroupDto[], void>({
            path: `/api/budget/${budgetId}/category-groups`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetCategories
    * @request GET:/api/budget/{budgetId}/categories
    * @secure
    * @response `200` `(CategoryDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getCategories = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<CategoryDto[], void>({
            path: `/api/budget/${budgetId}/categories`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetBudgetCollaborations
    * @request GET:/api/budget/{budgetId}/budget-collaborations
    * @secure
    * @response `200` `(BudgetCollaborationDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getBudgetCollaborations = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<BudgetCollaborationDto[], void>({
            path: `/api/budget/${budgetId}/budget-collaborations`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetTransactionTemplates
    * @request GET:/api/budget/{budgetId}/transaction-templates
    * @secure
    * @response `200` `(TransactionTemplateDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getTransactionTemplates = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<TransactionTemplateDto[], void>({
            path: `/api/budget/${budgetId}/transaction-templates`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetTransactionTemplateSplits
    * @request GET:/api/budget/{budgetId}/transaction-template-splits
    * @secure
    * @response `200` `(TransactionTemplateSplitDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getTransactionTemplateSplits = (
      budgetId: string,
      params: RequestParams = {}
   ) =>
      this.http
         .request<TransactionTemplateSplitDto[], void>({
            path: `/api/budget/${budgetId}/transaction-template-splits`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetTargets
    * @request GET:/api/budget/{budgetId}/targets
    * @secure
    * @response `200` `(TargetDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getTargets = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<TargetDto[], void>({
            path: `/api/budget/${budgetId}/targets`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetBankAccounts
    * @request GET:/api/budget/{budgetId}/bank-accounts
    * @secure
    * @response `200` `(BankAccountDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getBankAccounts = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<BankAccountDto[], void>({
            path: `/api/budget/${budgetId}/bank-accounts`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetLoans
    * @request GET:/api/budget/{budgetId}/loans
    * @secure
    * @response `200` `(LoanDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getLoans = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<LoanDto[], void>({
            path: `/api/budget/${budgetId}/loans`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetLoanSchedule
    * @request GET:/api/budget/{budgetId}/loans/{loanId}/schedule
    * @secure
    * @response `200` `(ScheduledLoanPaymentDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getLoanSchedule = (
      loanId: string,
      budgetId: string,
      params: RequestParams = {}
   ) =>
      this.http
         .request<ScheduledLoanPaymentDto[], void>({
            path: `/api/budget/${budgetId}/loans/${loanId}/schedule`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetLoansSchedule
    * @request GET:/api/budget/{budgetId}/loans-schedule
    * @secure
    * @response `200` `(ScheduledLoanPaymentDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getLoansSchedule = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<ScheduledLoanPaymentDto[], void>({
            path: `/api/budget/${budgetId}/loans-schedule`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetLoanActivities
    * @request GET:/api/budget/{budgetId}/loan-activities
    * @secure
    * @response `200` `(LoanActivityDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getLoanActivities = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<LoanActivityDto[], void>({
            path: `/api/budget/${budgetId}/loan-activities`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetFilters
    * @request GET:/api/budget/{budgetId}/filters
    * @secure
    * @response `200` `(FilterDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getFilters = (budgetId: string, params: RequestParams = {}) =>
      this.http
         .request<FilterDto[], void>({
            path: `/api/budget/${budgetId}/filters`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags Budget
    * @name GetAssignmentRecords
    * @request GET:/api/budget/{budgetId}/period/{periodKey}/assignment-records
    * @secure
    * @response `200` `(AssignmentRecordDto)[]` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   getAssignmentRecords = (
      budgetId: string,
      periodKey: string,
      params: RequestParams = {}
   ) =>
      this.http
         .request<AssignmentRecordDto[], void>({
            path: `/api/budget/${budgetId}/period/${periodKey}/assignment-records`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data)
}

const budgetApi = new BudgetApi(useHttpClient())
export function useBudgetApi(): BudgetApi {
   return budgetApi
}
