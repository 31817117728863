import { ref } from 'vue'

const currentTheme = ref<'dark' | 'light' | undefined>(undefined)

export default function useThemes() {
   return {
      init,
      setTheme,
      currentTheme
   }
}

const themeKey = 'easy-budget-theme'
const defaultTheme = 'light'

const changeTheme = (newTheme) => {
   const themeElement = document.getElementById('theme-link')
   if (themeElement) {
      const href = themeElement.getAttribute('href')
      const element = document.querySelector('html') // v4
      if (newTheme === 'dark') {
         element?.classList.add('app-dark') // v4
      } else {
         element?.classList.remove('app-dark') // v4
      }

      if (href?.includes('light')) {
         themeElement!.setAttribute(
            'href',
            themeElement.getAttribute('href')!.replace('light', newTheme)
         )
      } else {
         themeElement!.setAttribute(
            'href',
            themeElement.getAttribute('href')!.replace('dark', newTheme)
         )
      }
   }
}

function setTheme(theme: 'dark' | 'light') {
   localStorage.setItem(themeKey, theme)
   currentTheme.value = theme
   changeTheme(theme)
}

function init() {
   let theme = localStorage.getItem(themeKey) as 'dark' | 'light' | null

   if (theme == null) {
      theme = defaultTheme
   }

   setTheme(theme)
}
