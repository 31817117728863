import { useUserProfileApi } from '@/api/UserProfile'
import { QueryClient, useQuery } from 'vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { UserDto } from '@/api/contracts/models'

export function useUserProfileQuery() {
   const client = useUserProfileApi()

   return useQuery([QueryKeys.UserProfile], () => client.get())
}

export function useUserProfileQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useUserProfileApi()
   return queryClient.prefetchQuery<UserDto>([QueryKeys.UserProfile], () =>
      client.get()
   )
}
