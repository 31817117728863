import { ExpenseType } from '@/api/contracts/models'
import dayjs from 'dayjs'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

export function useParams() {
   const router = useRouter()
   const budgetId = computed(
      () => router.currentRoute.value.params.budgetId as string
   )
   const periodKey = computed(
      () =>
         (router.currentRoute.value.params.periodKey as string) ??
         dayjs().toDate().toPeriodKey()
   )
   const categoryGroupId = computed(
      () => router.currentRoute.value.params.categoryGroupId as string
   )
   const categoryId = computed(
      () => router.currentRoute.value.params.categoryId as string
   )
   const transactionId = computed(
      () => router.currentRoute.value.params.transactionId as string
   )
   const expenseType = computed(
      () => router.currentRoute.value.params.expenseType as ExpenseType
   )

   const onboardingProgress = computed(
      () => router.currentRoute.value.params.onboardingProgress as string
   )

   const transactionTemplateId = computed(
      () => router.currentRoute.value.params.transactionTemplateId as string
   )

   const transactionDraftIdQuery = computed(
      () => router.currentRoute.value.query.transactionDraftId as string
   )

   const transactionDraftId = computed(
      () => router.currentRoute.value.params.transactionDraftId as string
   )

   const templateDate = computed(
      () => router.currentRoute.value.params.templateDate as string
   )

   const bankAccountId = computed(
      () => router.currentRoute.value.params.bankAccountId as string
   )

   const loanId = computed(
      () => router.currentRoute.value.params.loanId as string
   )

   return {
      budgetId,
      periodKey,
      categoryId,
      categoryGroupId,
      transactionId,
      expenseType,
      onboardingProgress,
      transactionTemplateId,
      templateDate,
      bankAccountId,
      transactionDraftIdQuery,
      transactionDraftId,
      loanId
   }
}
