import * as validators from '@vuelidate/validators'
import { useInternationalization } from '@/i18n'
const { createI18nMessage } = validators

import * as custom from './customValidators'

const withI18nMessage = createI18nMessage({
   t: useInternationalization().global.t
})

export const required = withI18nMessage(validators.required)

export const minValue = (minValue: number) =>
   withI18nMessage(
      validators.minValue(
         Math.round(((minValue as number) + Number.EPSILON) * 100) / 100
      ),
      {
         withArguments: true
      }
   )

export const maxValue = (maxValue: number) =>
   withI18nMessage(
      validators.maxValue(
         Math.round(((maxValue as number) + Number.EPSILON) * 100) / 100
      ),
      {
         withArguments: true
      }
   )

export const minLength = (minLength: number) =>
   withI18nMessage(validators.minLength(minLength), {
      withArguments: true
   })

export const splitsEqual = withI18nMessage(custom.splitsEqual)

export const email = withI18nMessage(validators.email)
