import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import googleAnalytics from '@analytics/google-analytics'
import { EventName } from '@/types/types'

const measurementId =
   process.env.VUE_APP_EB_ENV === 'Production' ? 'G-R6WZV6LBC7' : 'G-FEB7PRRFCP'

const getActivePlugins = () => {
   if (process.env.VUE_APP_EB_ENV === 'Production') {
      return [
         googleTagManager({
            containerId: 'GTM-T99D4V7'
         }),
         googleAnalytics({
            trackingId: 'UA-177513962-2',
            measurementIds: measurementId
         })
      ]
   }

   return [
      googleAnalytics({
         measurementIds: measurementId
      })
   ]
}

const shouldRunInDebugMode = () => {
   if (process.env.VUE_APP_EB_ENV === 'Production') {
      return false
   }

   return true
}

const analytics = Analytics({
   app: 'EasyBudget',
   plugins: getActivePlugins(),
   debug: shouldRunInDebugMode()
})

export const publishEvent = (eventName: EventName, payload = {}) => {
   analytics.track(eventName, payload)
}

export function useGoogleAnalyticsData() {
   const id = measurementId.slice(2)
   const pattern = new RegExp('_ga_' + id + '=GS\\d\\.\\d\\.(.+?)(?:;|$)')
   const match = document.cookie.match(pattern)
   const parts = match?.[1].split('.')

   if (!parts) {
      return undefined
   }

   return {
      ga_client_id: getGAClientId(),
      ga_session_id: parts.shift(),
      ga_session_number: parts.shift()
   }
}

function getGAClientId() {
   const pattern = new RegExp('_ga=GA\\d\\.\\d\\.(.+?)(?:;|$)')
   const match = document.cookie.match(pattern)
   return match?.[1]
}
