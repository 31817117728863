import { RouteRecordRaw } from 'vue-router'
import authRoutes from './auth'
import authGuard from '@/auth/AuthGuard'
import { lazyLoadView } from './helpers'
import accountDisabledGuard from './accountDisabledGuard'
import subscriptionGuard from './subscriptionGuard'

export const mobileRoutes: Array<RouteRecordRaw> = [
   ...authRoutes,
   {
      path: '/health',
      name: 'health',
      component: lazyLoadView(
         () => import(/* webpackPrefetch: true */ '../views/_Health.vue')
      )
   },
   {
      path: '/',
      name: 'app',
      beforeEnter: [authGuard, accountDisabledGuard],
      component: () =>
         import(/* webpackPrefetch: true */ '../views/MainView.vue'),

      children: [
         {
            path: 'subscription-expired',
            name: 'subscriptionExpired',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../views/SubscriptionExpired.vue'
                  )
            )
         },
         {
            path: 'subscription-extended',
            name: 'subscriptionExtended',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../views/SubscriptionExtended.vue'
                  )
            )
         },
         {
            path: 'special-offer',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../layouts/LayoutMobileDialog.vue'
                  )
            ),
            children: [
               {
                  path: '',
                  name: 'specialOffer',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/SpecialOfferView.vue'
                           )
                     )
                  }
               }
            ]
         },
         {
            path: 'settings',
            component: lazyLoadView(
               () =>
                  import(
                     /* webpackPrefetch: true */ '../layouts/LayoutMobileDialog.vue'
                  )
            ),
            children: [
               {
                  path: '',
                  name: 'settings',
                  meta: { header: 'Ustawienia' },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/SettingsViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: 'subscription',
                  name: 'settingsSubscription',
                  meta: { header: 'Subskrypcja' },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/SettingsSubscriptionViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: 'reminder',
                  name: 'settingsReminder',
                  meta: { header: 'Powiadomienia' },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/SettingsReminderViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: 'referral',
                  name: 'settingsReferral',
                  meta: { header: 'Polecaj' },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/SettingsReferralViewMobile.vue'
                           )
                     )
                  }
               }
            ]
         },
         {
            path: 'budget',
            beforeEnter: subscriptionGuard,
            component: lazyLoadView(
               () => import('../layouts/LayoutMobile.vue')
            ),
            children: [
               {
                  path: ':budgetId/settings',
                  name: 'budgetSettings',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/BudgetSettingsViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/statistics',
                  name: 'statistics',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/StatisticsViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey',
                  alias: [':budgetId'],
                  name: 'budget',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/BudgetViewContainerMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/bankAccounts',
                  name: 'bankAccounts',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/BankAccountsView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/bankAccounts/:bankAccountId',
                  name: 'bankAccount',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/BankAccountView.vue'
                           )
                     )
                  }
               }
            ]
         },
         {
            path: 'budget',
            beforeEnter: subscriptionGuard,
            component: lazyLoadView(
               () => import('../layouts/LayoutMobileDialog.vue')
            ),
            children: [
               {
                  path: ':budgetId/period/:periodKey/assign',
                  name: 'budgetAssign',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/BudgetAssignViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/auto-assign',
                  name: 'autoAssign',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/AutoAssignView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/assignment-history',
                  name: 'assignmentHistory',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/AssignmentHistoryView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId',
                  name: 'budgetCategory',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/CategorySideviewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId/move-money',
                  name: 'moveMoney',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/MoveMoneyView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId/move',
                  name: 'moveCategory',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/MoveCategoryView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId/target',
                  name: 'targetView',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/TargetView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId/move-transactions',
                  name: 'moveTransactions',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/MoveTransactionsView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId/cover',
                  name: 'coverOverspending',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/CoverOverspendingView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId?/transactions/:transactionId?',
                  name: 'budgetCategoryTransaction',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/TransactionViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId?/templates/:transactionTemplateId?',
                  name: 'transactionTemplate',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/TransactionTemplateView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/categories/:categoryId?/templates/:transactionTemplateId/date/:templateDate/confirm',
                  name: 'transactionDraft',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/TransactionDraftView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/period/:periodKey/transaction-draft/:transactionDraftId',
                  name: 'transactionDraftIntegration',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/IntegrationTransactionDraftView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/group/:categoryGroupId/category/:categoryId',
                  name: 'budgetCategoryMobile',
                  meta: { header: 'Edytuj kategorię' },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/CategoryViewMobile.vue'
                           )
                     )
                  },
                  props: {
                     default: true,
                     mobile: (route) => ({
                        onDeleteBackValue: route.params.onDeleteBackValue
                     })
                  }
               },
               {
                  path: ':budgetId/group/:categoryGroupId/category/:categoryId',
                  name: 'budgetCategoryDeleteMobile',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/CategoryDeleteViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/group/:categoryGroupId',
                  name: 'budgetCategoryAddMobile',
                  meta: { header: 'Dodaj kategorię' },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/CategoryViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/category-group/:categoryGroupId?',
                  name: 'budgetCategoryGroupMobile',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/CategoryGroupViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/category-group/:categoryGroupId/delete',
                  name: 'budgetCategoryGroupDeleteMobile',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/CategoryGroupDeleteViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/:periodKey/transactions',
                  name: 'budgetTransaction',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/TransactionViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId?/edit',
                  name: 'budgetModal',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/BudgetModalViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/invite',
                  name: 'budgetInvite',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/BudgetInviteViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId?/list',
                  name: 'budgetsList',
                  meta: { header: 'Twoje budżety' },
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/SettingsBudgetListViewMobile.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/bankAccount/:bankAccountId?/modal',
                  name: 'bankAccountEdit',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/BankAccountModalView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/bankAccount/:bankAccountId?/close-modal',
                  name: 'bankAccountClose',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/BankAccountCloseModalView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/bankAccount/:bankAccountId/reconcile',
                  name: 'bankAccountReconcile',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/BankAccountReconcileView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/bankAccount/:bankAccountId/reconcile-edit',
                  name: 'bankAccountReconcileEdit',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/BankAccountReconcileEditView.vue'
                           )
                     )
                  }
               },
               {
                  path: ':budgetId/bankAccount/:bankAccountId/transfer',
                  name: 'bankAccountTransfer',
                  components: {
                     main: lazyLoadView(
                        () =>
                           import(
                              /* webpackPrefetch: true */ '../views/mobile/BankAccountTransferView.vue'
                           )
                     )
                  }
               }
            ]
         }
      ]
   }
]
