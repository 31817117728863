import { ContentType, HttpClient, RequestParams } from './base/BaseHttpClient'
import { useHttpClient } from './base/HttpClient'
import { RecordUserVisitRequest, UserDto } from './contracts/models'

export class UserProfileApi<SecurityDataType = unknown> {
   http: HttpClient<SecurityDataType>

   constructor(http: HttpClient<SecurityDataType>) {
      this.http = http
   }

   /**
    * No description
    *
    * @tags UserProfile
    * @name Get
    * @request GET:/api/user-profile
    * @secure
    * @response `200` `UserDto` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   get = (params: RequestParams = {}) =>
      this.http
         .request<UserDto, void>({
            path: `/api/user-profile`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
         })
         .then((m) => m.data) /**
    * No description
    *
    * @tags UserProfile
    * @name RecordVisit
    * @request PUT:/api/user-profile/visit
    * @secure
    * @response `200` `void` Success
    * @response `401` `void` Unauthorized
    * @response `403` `void` Forbidden
    */
   recordVisit = (data: RecordUserVisitRequest, params: RequestParams = {}) =>
      this.http
         .request<void, void>({
            path: `/api/user-profile/visit`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
         })
         .then((m) => m.data)
}

const userProfileApi = new UserProfileApi(useHttpClient())
export function useUserProfileApi(): UserProfileApi {
   return userProfileApi
}
