import { router } from './router'
import App from './App.vue'
import './registerServiceWorker'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/pl'
import vueDebounce from 'vue-debounce'
import { createApp } from 'vue'

import 'awesome-notifications/dist/style.css'
require('vue-tour/dist/vue-tour.css')
import 'tippy.js/themes/light.css'

/* PrimeVue + PrimeIcons + PrimeFlex */
import 'primeicons/primeicons.css'

import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura'
import { definePreset } from '@primevue/themes'
import Tooltip from 'primevue/tooltip'
/* End Prime */

import '@/validation/index'
import 'tippy.js/dist/tippy.css'
import vClickOutside from 'click-outside-vue3'
import '@/helpers/Analytics'
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import '@/helpers/Extensions'
import { VueQueryPlugin } from 'vue-query'

import { EasyToastPlugin } from '@/plugins/easy-toast/EasyToastPlugin'
import { useEventBus } from './composables/useEventBus'
import { useDeviceInfo } from './composables/useDeviceInfo'
import { useInternationalization } from '@/i18n'
import Ripple from '@/directives/Ripple/RippleDirective'
import '@/directives/Ripple/styles.css'
import ConfirmationService from 'primevue/confirmationservice'
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import VueShepherdPlugin from 'vue-shepherd'
import 'shepherd.js/dist/css/shepherd.css'
import { VueQueryDevTools } from 'vue-query/devtools'
import { setUpGA4Events } from '@/analytics'
import VueNumber from 'vue-number-animation'
import { createPinia } from 'pinia'
import 'floating-vue/dist/style.css'
import './styles.css'

setUpGA4Events()

Chart.register(annotationPlugin)
const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(VueNumber)
app.use(ConfirmationService)
app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.use(useInternationalization())
app.use(VueShepherdPlugin)

app.use(EasyToastPlugin, {
   eventBus: useEventBus(),
   isMobile: useDeviceInfo().isMobile
})

app.use(VueQueryPlugin, {
   // TODO: EB-711
   // queryClientConfig: {
   //    defaultOptions: {
   //       queries: {
   //          refetchOnWindowFocus: false
   //       }
   //    }
   // }
})

const preset = definePreset(Aura, {
   primitive: {
      orange: {
         50: '#fffbf4',
         100: '#ffebcc',
         200: '#ffdba4',
         300: '#ffcb7c',
         400: '#ffbb53',
         500: '#ffab2b',
         600: '#d99125',
         700: '#b3781e',
         800: '#8c5e18',
         900: '#664411',
         950: '#664411'
      }
   },
   semantic: {
      primary: {
         50: '{orange.50}',
         100: '{orange.100}',
         200: '{orange.200}',
         300: '{orange.300}',
         400: '{orange.400}',
         500: '{orange.500}',
         600: '{orange.600}',
         700: '{orange.700}',
         800: '{orange.800}',
         900: '{orange.900}',
         950: '{orange.950}'
      },
      colorScheme: {
         light: {
            surface: {
               0: '#ffffff',
               50: '{slate.50}',
               100: '{slate.100}',
               200: '{slate.200}',
               300: '{slate.300}',
               400: '{slate.400}',
               500: '{slate.500}',
               600: '{slate.600}',
               700: '{slate.700}',
               800: '{slate.800}',
               900: '{slate.900}',
               950: '{slate.950}'
            }
         },
         dark: {
            surface: {
               0: '#ffffff',
               50: '#e6e7e8',
               100: '#cdcfd1',
               200: '#b4b6ba',
               300: '#9b9ea3',
               400: '#82868c',
               500: '#686e75',
               600: '#4f565e',
               700: '#0b213f',
               800: '#1d2530',
               900: '#071426',
               950: '#040d19'
            }
         }
      }
   }
})

app.use(PrimeVue, {
   theme: {
      preset: preset,
      options: {
         darkModeSelector: '.app-dark',
         cssLayer: {
            name: 'primevue',
            order: 'tailwind-base, primevue, tailwind-utilities'
         }
      }
   },
   locale: {
      aria: {
         navigation: ''
      },
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Wyczyść',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Tak',
      reject: 'Nie',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      firstDayOfWeek: 1,
      dayNames: [
         'Niedziela',
         'Poniedziałek',
         'Wtorek',
         'Środa',
         'Czwartek',
         'Piątek',
         'Sobota'
      ],
      dayNamesShort: ['Niedz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
      dayNamesMin: ['N', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb'],
      monthNames: [
         'Styczeń',
         'Luty',
         'Marzec',
         'Kwiecień',
         'Maj',
         'Czerwiec',
         'Lipiec',
         'Sierpień',
         'Wrzesień',
         'Październik',
         'Listopad',
         'Grudzień'
      ],
      monthNamesShort: [
         'Sty',
         'Lut',
         'Mar',
         'Kwi',
         'Maj',
         'Cze',
         'Lip',
         'Sie',
         'Wrz',
         'Paź',
         'Lis',
         'Gru'
      ]
   }
})

app.use(vClickOutside)

app.use(Vue3Tour)

if (process.env.VUE_APP_EB_ENV === 'Production') {
   const pjson = require('../package.json')
   Sentry.init({
      app,
      environment: 'Prod',
      release: 'EasyBudget.UI@' + pjson.version,
      dsn: 'https://e17c738ec11b463798e8a7635c58549a@o932785.ingest.sentry.io/5881700',
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
         new Sentry.Replay(),
         new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [process.env.VUE_APP_APP_BASE_URL!, /^\//]
         })
      ],
      tracesSampleRate: 1
   })
}

app.use(router)

app.use(vueDebounce, {
   lock: true,
   listenTo: ['input'],
   defaultTime: '700'
})

dayjs.locale('pl')
dayjs.extend(duration)
dayjs.duration({ months: 12 })

if (process.env.VUE_APP_EB_ENV !== 'Production') {
   app.config.performance = true
}

app.mount('#app')
