
import { defineComponent } from 'vue'
import { useFreshDesk } from './composables/useFreshDesk'
import useThemes from './composables/useThemes'

export default defineComponent({
   name: 'App',
   setup() {
      const { hideLauncher } = useFreshDesk()
      const { init } = useThemes()
      init()
      hideLauncher()
      return {}
   }
})
