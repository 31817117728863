import { ObjectDirective } from 'vue'

const RippleDirective = (): ObjectDirective => ({
   mounted(el: HTMLElement) {
      el.addEventListener('click', function () {
         el.classList.add('c-ripple-click')
      })

      el.addEventListener('webkitAnimationEnd', function () {
         el.classList.remove('c-ripple-click')
      })
   },

   unmounted(el: HTMLElement) {
      //TODO
   }
})

const Ripple = RippleDirective()
export default Ripple
